import logo from './logo.svg';
import './App.css';
import { useEffect, useState, useRef } from 'react';

function App() {

  const uRef = useRef();

  // The size of the list
  // It will be updated later
  const [w, setW] = useState();
  const [h, setH] = useState();


  useEffect(() => {
    window.addEventListener("resize", getListSize);
    getListSize()
  }, []);

  const getListSize = () => {
    const newWidth = uRef.current.clientWidth;
    setW(newWidth);

    const newHeight = uRef.current.clientHeight;
    setH(newHeight);
  };

  const [n, setN] = useState(10)



  // THIS IS THE FUNCTION TO CHANGE EVERYTHING ELSE STAYS THE SAME

  const renderSquares = () => {

    let squares = [];
    let d = 0;
    let dBest = 100;
    let diffBest = 1000000000000000000000000000000000;

    // let ss = width > height ? height : width
    // let ls = width > height ? width : height

    let a = w * h;

    let yOG = Math.sqrt((n * (h)) / w)
    let xOG = Math.sqrt((n * (w)) / h)
    let x = 0;
    let y = 0;

    console.log("Before x : ", x, " y: ", y, ' Product: ', x * y)



    y = Math.ceil(yOG)
    x = Math.ceil(xOG)
    if (x * y >= n) {
      let [diff1, d1] = calcDiff(w, h, x, y, a)
      if (diffBest > diff1) {
        dBest = d1
      }
    }

    y = Math.ceil(yOG)
    x = Math.floor(xOG)
    if (x * y >= n) {
      let [diff2, d2] = calcDiff(w, h, x, y, a)
      if (diffBest > diff2) {
        dBest = d2
      }
    }

    y = Math.floor(yOG)
    x = Math.ceil(xOG)
    if (x * y >= n) {
      let [diff3, d3] = calcDiff(w, h, x, y, a)
      if (diffBest > diff3) {
        dBest = d3
      }
    }

    y = Math.floor(yOG)
    x = Math.floor(xOG)
    if (x * y >= n) {
      let [diff4, d4] = calcDiff(w, h, x, y, a)
      if (diffBest > diff4) {
        dBest = d4
      }
    }

    // console.log("x: ", x, " y: ", y, ' Product: ', x * y)

    // let dx = w / x;
    // let dy = h / y;

    // d = dx > dy ? dy : dx

    // console.log("d: ", d)

    // let diff = a - ((d * x) * (d * y));

    // console.log("a: ", a, " aTaken: ", ((d * x) * (d * y)), " diff: ", diff)




    console.log("dBest: ", dBest)
    for (let i = 0; i < n; ++i) {
      squares.push(
        createSquare(dBest)
      )
    }

    return squares

  }

  //END

  const calcDiff = (w, h, x, y, a) => {
    console.log("x: ", x, " y: ", y, ' Product: ', x * y)

    let dx = w / x;
    let dy = h / y;

    let d = dx > dy ? dy : dx

    console.log("d: ", d)

    let diff = a - ((d * x) * (d * y));

    console.log("a: ", a, " aTaken: ", ((d * x) * (d * y)), " diff: ", diff)
    return [diff, d];
  }

  const createSquare = (d) => {
    //to handle padding
    let dS = d - 20
    return (
      <div className='square' style={{ width: (dS.toString() + 'px'), height: (dS.toString() + 'px') }}>
        <div className='innerSquare' style={{ width: (dS.toString() + 'px'), height: (dS.toString() + 'px') }}></div>
      </div>
    )
  }

  // console.log("Width: ", width)
  // console.log("Height: ", height)


  return (
    <div className="App">
      <input value={n} onChange={(e) => { setN(e.target.value) }}></input>
      <div className='container' ref={uRef}>

        {renderSquares(n)}

      </div>
    </div>
  );
}

export default App;
